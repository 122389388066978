<template>
  <div class="container">
    <div class="checkin">
      <el-row :gutter="20">
        <el-col :span="5">
          <Serviceleft :activeIndex="leftPannelSelected"/>
        </el-col>
        <el-col :span="19">
          <div class="right_pannel" style="padding: 0">
            <div>
              <div style="width:100%;overflow:hidden; height:120px;border-radius:10px;">
                <img src="https://static.a6air.com/resource/banner/20210330/pannel_top_dynamic.jpg">
              </div>
              <div style="background:#fff">
              <div class="top-pannel">
                <div class="panel-header" style="width: 800px;">
                  <ul class="panel-tabs" style="width:250px;">
                    <li :class="loginType=='AIRPORT'?'active':''">
                      <a href="javascript:void(0)" @click="changeTab('AIRPORT')">按出发到达地</a>
                    </li>
                    <li :class="loginType=='FLIGHTNO'?'active':''">
                      <a href="javascript:void(0)" @click="changeTab('FLIGHTNO')">按航班号</a>
                    </li>
                  </ul>
                </div>
                <div style="margin-left: 20px; margin-top: 20px">
                  <el-row v-if="loginType=='AIRPORT'">
                    <el-col :span="5" class="form-group">
                      <div class="filter">
                        <!-- <p class="filter-title">出发城市:</p> -->
                        <div class="filter-input">
                          <el-autocomplete
                            class="inline-input"
                            style="width:180px"
                            v-model="dynamicFilter.deptAirport"
                            clearable
                            :fetch-suggestions="querySearchDynamic"
                            placeholder="出发机场"
                            @select="handleSelectDynamic('dept', $event)"
                            @blur="handleSearchOnblur(dynamicFilter,$refs.deptAirportPannelDynamic,'dept', $event)"
                            @clear="dynamicFilter.deptAirport='';dynamicFilter.depAirportTriCode='';"

                          >
                           <template slot-scope="{ item }">
                                <div class="name">{{ item.cityName }}</div>
                            </template>
                          </el-autocomplete>
                          <AirportPannel
                                ref="deptAirportPannelDynamic"
                                @selected="handleSelectDynamic('dept', $event)"
                            ></AirportPannel>
                        </div>
                      </div>
                    </el-col>
                    <el-col :span="1" class="form-group">
                      <i
                        class="el-icon-sort separet"
                        style="margin:20px 5px 20px"
                        @click="changeFlightsAirports"
                      ></i>
                    </el-col>
                    <el-col :span="7" class="form-group">
                      <div class="filter">
                        <!-- <p class="filter-title">到达机场:</p> -->
                        <div class="filter-input">
                          <el-autocomplete
                            class="inline-input"
                            style="width:180px"
                            v-model="dynamicFilter.arrAirport"
                            clearable
                            :fetch-suggestions="querySearchDynamic2"
                            placeholder="到达机场"
                            @select="handleSelectDynamic('arr', $event)"
                            @blur="handleSearchOnblur(dynamicFilter,$refs.arrAirportPannelDynamic,'arr', $event)"
                            @clear="dynamicFilter.arrAirport='';dynamicFilter.arrAirportTriCode='';"

                          >
                            <template slot-scope="{ item }">
                              <div class="name">{{ item.cityName }}</div>
                            </template>
                          </el-autocomplete>
                          <AirportPannel
                            ref="arrAirportPannelDynamic"
                             @selected="handleSelectDynamic('arr', $event)"
                          ></AirportPannel>
                        </div>
                      </div>
                    </el-col>
                    <el-col :span="7" class="form-group" v-if="loginType=='AIRPORT'">
                      <div class="filter">
                        <!-- <p class="filter-title">起飞日期:</p> -->
                        <div class="filter-input">
                          <el-date-picker
                            v-model="dynamicFilter.flightDate"
                            value-format="yyyy-MM-dd"
                            style="width:180px"
                          ></el-date-picker>
                        </div>
                      </div>
                    </el-col>
                    <el-col :span="3" class="form-group" v-if="loginType=='AIRPORT'">
                      <el-button
                        type="danger"
                        style="width:100px;border-radius: 5px; margin-top: 9px;"
                        @click="fetchDynamicDataHandler"
                      >查&nbsp;询</el-button>
                    </el-col>
                  </el-row>
                  <!-- <el-row v-if="loginType=='AIRPORT'">
                    <el-col :span="7" class="form-group">
                      <div class="filter">
                        <div class="filter-input">
                          <el-date-picker
                            v-model="dynamicFilter.deptDate"
                            default-value="new Date()"
                            value-format="yyyy-MM-dd"
                            style="width:180px"
                          ></el-date-picker>
                        </div>
                      </div>
                    </el-col>
                    <el-col :span="3" class="form-group">
                      <el-button
                        type="danger"
                        style="width:100px;border-radius: 5px; margin-top: 9px;"
                        @click="fetchDynamicDataHandler"
                      >查&nbsp;询</el-button>
                    </el-col>
                  </el-row> -->
                  <el-row v-if="loginType=='FLIGHTNO'">
                    <el-col :span="7" class="form-group">
                      <div class="filter">
                        <!-- <p class="filter-title">航班号:</p> -->
                        <div class="filter-input">
                          <el-input
                            id="certNo"
                            v-model="dynamicFilter.ShortFlightNo"
                            placeholder="请输入航班号"
                            style="width:180px"
                          >
                            <i slot="prefix" class="el-input__icon">A6</i>
                          </el-input>
                        </div>
                      </div>
                    </el-col>
                    <el-col :span="7" class="form-group">
                      <div class="filter">
                        <!-- <p class="filter-title">起飞日期:</p> -->
                        <div class="filter-input">
                          <el-date-picker
                            v-model="dynamicFilter.flightDate"
                            value-format="yyyy-MM-dd"
                            style="width:180px"
                          ></el-date-picker>
                        </div>
                      </div>
                    </el-col>
                    <el-col :span="3" class="form-group">
                      <el-button
                        type="danger"
                        style="width:100px;border-radius: 5px; margin-top: 9px;"
                        @click="fetchDynamicDataHandler"
                      >查&nbsp;询</el-button>
                    </el-col>
                  </el-row>
                </div>
              </div>
              <div style="width:100%;margin-top:10px;" class="aoctable" v-show="!detailVisible">
                <el-table
                  :data="tableData"
                  v-loading="scheduleLoading"
                  @row-click="handleDetail"
                  stripe
                  style="width: 100%;border-radius:10px;border-collapse: separate;border-spacing:0px"
                >
                  <el-table-column prop="flightDate" width="100px" label="日期"></el-table-column>
                  <el-table-column prop="flightNo" label="航班号"></el-table-column>
                  <el-table-column label="出发-到达" width="180px">
                    <template slot-scope="scope">
                      <div>
                        {{scope.row.depAirportName}}{{scope.row.depPortTerm ? scope.row.depPortTerm : ""}}
                        <br>
                        {{scope.row.arrAirportName}}{{scope.row.arrPortTerm ? scope.row.arrPortTerm : ""}}
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="schedule.std" label="起飞时间">
                    <template slot-scope="scope">{{"计划:"}} {{scope.row.std | formatDate}}
                      <br>
                      <span> {{scope.row.atd ? "实际:" : "预计:"}} {{ (scope.row.atd ? scope.row.atd : scope.row.etd ? scope.row.etd : "---") | formatDate}} </span>
                    </template>
                  </el-table-column>
                  <el-table-column label="到达时间">
                    <template slot-scope="scope">{{"计划:"}} {{scope.row.sta | formatDate}}
                    <br>
                      <span>{{scope.row.ata ? "实际:" : "预计:"}} {{ (scope.row.ata ? scope.row.ata : scope.row.eta ? scope.row.eta : "---") | formatDate}}</span>
                    </template>
                  </el-table-column>
                  <el-table-column label="登机口" align="center">
                    <template slot-scope="scope">{{scope.row.boardGate ? scope.row.boardGate : "---"}}</template>
                  </el-table-column>
                  <el-table-column label="航班状态" align="center">
                    <template slot-scope="scope"> {{scope.row.status | formateStatus }} 
                      <img class="fly-status" src="../../assets/dynamic_return.png" v-if="scope.row.abnormalType == '2'">
                      <img class="fly-status" src="../../assets/dynamic_flydown.png" v-if="scope.row.abnormalType == '1'">
                      <img class="fly-status" src="../../assets/dynamic_delay.png" v-if="scope.row.abnormalType == '4'">
                    </template>
                  </el-table-column>
                  <template slot="empty">
                    <div class="noData">暂无计划信息</div>
                  </template>
                </el-table>
              </div>
              <div class="wechat-detail" v-show="detailVisible" >
                <el-dialog class="detail-dialog" width="700" :visible.sync="detailVisible" :center="true">
                <!-- 详情部分 -->
                <div slot="title" class="detail-content-top-title">
                        <span>航班详情</span>
                      </div>
                  <div class="detail-content" v-if="dynamicDetail">
                    <div class="detail-content-top">
                      <div class="detail-content-top-flight">
                        <span style="float:left;">{{dynamicDetail.depAirportName}}{{dynamicDetail.depPortTerm ? dynamicDetail.depPortTerm : ""}}</span>
                        <span class="flight-no-bg" style="color:#333;">
                          {{dynamicDetail.flightNo}}
                        </span>
                        <span style="float:right">{{ dynamicDetail.arrAirportName }}{{dynamicDetail.arrPortTerm ? dynamicDetail.arrPortTerm : ""}}</span>
                      </div>
                    </div>
                    <div class="detail-content-detail">
                      <div>
                        <div class="date" >
                          <div class="date-plan">
                            <span>计划起飞: </span>
                            <span class="detail"> {{ (dynamicDetail.std ? dynamicDetail.std  : "---") | formatDate}} </span>
                          </div>
                          <div class="date-plan">
                            <span>计划到达: </span>
                            <span class="detail"> {{ (dynamicDetail.sta ? dynamicDetail.sta : "---") | formatDate}} </span>
                          </div>
                          <div class="date-plan">
                            <span>飞行时长: </span>
                            <span class="detail">{{ getDiffMinute(dynamicDetail.std, dynamicDetail.sta) | formatDateDuring }}</span>
                          </div>
                          <div class="date-plan">
                            <span>实际起飞: </span>
                            <span class="detail"> {{ (dynamicDetail.atd ? dynamicDetail.atd  : "---") | formatDate}} </span>
                          </div>
                          <div class="date-plan">
                            <span>实际到达: </span>
                            <span class="detail"> {{ (dynamicDetail.ata ? dynamicDetail.ata  : "---") | formatDate}} </span>
                          </div>
                          <div class="date-plan">
                            <span>登机口: </span>
                            <span class="detail"> {{dynamicDetail.boardGate ? dynamicDetail.boardGate : "---"}} </span>
                          </div>
                          <div class="date-plan">
                            <span>值机柜台: </span>
                            <span class="detail"> {{dynamicDetail.checkInCounter ? dynamicDetail.checkInCounter : "---"}} </span>
                          </div>
                          <div class="date-plan">
                            <span>行李转盘: </span>
                            <span id="detail"> {{dynamicDetail.luggageCarousel ? dynamicDetail.luggageCarousel  : "---"}} </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </el-dialog>
            </div>
              <div style="margin-top:110px;height:200px">
                <div class="warm-prompt" v-html="warmTips">

                </div>
              </div>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import Serviceleft from "../../components/ServiceLeft";
import {
  fetchAirports,
  getDynamicData,
  getDynamicDetail,
  getOneByAliasCode
} from "@/api/newsapi";
import moment from "moment";
import AirportPannel from "../../components/AirportPannel";
import {formatDate} from "element-ui/src/utils/date-util";
export default {
  components: { Serviceleft, AirportPannel },
  data() {
    return {
      leftPannelSelected: "li_dynamic",
      loginType: "AIRPORT",
      input: "",
      timeDefaultShow: new Date(),
      scheduleLoading: false,
      warmTips: "",
      defaultAirport: {},
      dynamicFilter: {
        dynamicType: "AIRPORT",
        deptAirport: "长沙",
        arrAirport: "昆明",
        ShortFlightNo: "",
        arrAirportTriCode: "KMG",
        depAirportTriCode: "CSX",
        flightDate: formatDate(new Date())
      },
      restaurants: [],
      tableData: [],
      detailVisible: false,
      dynamicDetail: null, // 详情信息
      airportInfo: {}
    };
  },
  filters: {
    formatDate(timestamp) {
      if (timestamp == null) {
        return "-";
      }
      if (timestamp === "---") {
        return "---";
      }
      return moment(timestamp).format("HH:mm");
    },
    formatDateDuring(mss) {
      var hoursRound = Math.floor(mss / 60);
      var minutes = parseInt(mss % 60);
      return hoursRound + "小时" + minutes + "分";
    },
    formatYYMMDD(timestamp) {
      if (timestamp == null) {
        return "-";
      }

      let week = moment(timestamp).day();
      let weekStr = "";
      switch (week) {
        case 1:
          weekStr = "周一";
          break;
        case 2:
          weekStr = "周二";
          break;
        case 3:
          weekStr = "周三";
          break;
        case 4:
          weekStr = "周四";
          break;
        case 5:
          weekStr = "周五";
          break;
        case 6:
          weekStr = "周六";
          break;
        case 0:
          weekStr = "周日";
          break;
      }

      return moment(timestamp).format("YYYY年MM月DD日 ") + weekStr;
    },
    formateStatus(val) {
      switch (val) {
        case 0:
          return "计划";
        case 1:
          return "正在登机";
        case 2:
          return "登机结束";
        case 3:
          return "已关舱门";
        case 4:
          return "已滑出";
        case 5:
          return "飞行中";
        case 6:
          return "到达";
        case 7:
          return "取消";
        case 8:
          return "延误";
        default:
          return "计划";
      }
    }
  },
  mounted: async function() {
    document.title = this.$route.meta.title;
    this.restaurants = this.getAirports();
    console.log('restaurants', this.restaurants);
    this.$route.query.deptAirportCode &&
      (this.dynamicFilter.depAirportTriCode = this.$route.query.deptAirportCode);
    var airpotsItem = this.getAirport(this.dynamicFilter.depAirportTriCode);
    if (airpotsItem != undefined) {
      this.dynamicFilter.deptAirport = airpotsItem.cityName;
    }

    this.$route.query.arrAirportCode &&
      (this.dynamicFilter.arrAirportTriCode = this.$route.query.arrAirportCode);
    airpotsItem = this.getAirport(this.dynamicFilter.arrAirportTriCode);
    if (airpotsItem != undefined) {
      this.dynamicFilter.arrAirport = airpotsItem.cityName;
    }
    this.dynamicFilter.flightDate = this.$route.query.deptDate;
    this.dynamicFilter.flightNo = this.$route.query.flightNo;

    if (
      this.dynamicFilter.flightDate == undefined ||
      this.dynamicFilter.flightDate.length <= 0
    ) {
      var date = new Date();
      var today =
        date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
      this.dynamicFilter.flightDate = today;
    }

    if (this.$route.query.dynamicType != undefined) {
      this.dynamicFilter.dynamicType = this.$route.query.dynamicType;
      if (
        this.dynamicFilter.dynamicType != undefined &&
        this.dynamicFilter.dynamicType.length > 0
      ) {
        this.airportInfo = {
          arrAirport: this.dynamicFilter.arrAirport,
          arrAirportTriCode: this.dynamicFilter.arrAirportTriCode,
          deptAirport: this.dynamicFilter.deptAirport,
          depAirportTriCode: this.dynamicFilter.depAirportTriCode,
          flightDate: this.dynamicFilter.flightDate
        }
        this.changeTab(this.dynamicFilter.dynamicType);
      }
    }
    if (
      ((this.dynamicFilter.depAirportTriCode.length > 0 &&
        this.dynamicFilter.arrAirportTriCode.length > 0) ||
        this.dynamicFilter.flightNo.length > 0) &&
      this.dynamicFilter.flightDate.length > 0
    ) {
      this.fetchDynamicDataHandler();
    }
    this.loadWarm();
  },
  methods: {
    loadWarm() {
      getOneByAliasCode({ aliasCode: "dynamic_warm_tips" }).then(res => {
        if (res.success) {
          this.warmTips = res.data.newsContent;
        }
      });
    },
    changeFlightsAirports() {
      var chineseName = this.dynamicFilter.deptAirport;
      var airportCode = this.dynamicFilter.depAirportTriCode;
      this.dynamicFilter.deptAirport = this.dynamicFilter.arrAirport;
      this.dynamicFilter.depAirportTriCode = this.dynamicFilter.arrAirportTriCode;
      this.dynamicFilter.arrAirport = chineseName;
      this.dynamicFilter.arrAirportTriCode = airportCode;
    },
    selectPannel(pannelId) {
      // console.log(pannelId);
      document.getElementById("li_booking").setAttribute("class", "");
      document.getElementById("li_aoc").setAttribute("class", "");
      document.getElementById("li_checkin").setAttribute("class", "");
      document.getElementById("li_order").setAttribute("class", "");
      document.getElementById("li_xl").setAttribute("class", "");
      document.getElementById(pannelId).setAttribute("class", "active");
    },
    changeTab(tab) {
      this.loginType = tab;
      this.dynamicFilter =
        tab === "AIRPORT"
          ? Object.assign( {
              dynamicType: tab,
              deptAirport: "长沙",
              arrAirport: "昆明",
              ShortFlightNo: "",
              arrAirportTriCode: "KMG",
              depAirportTriCode: "CSX",
              flightDate: formatDate(new Date()),
              
            }, this.airportInfo)
          : {
              dynamicType: tab,
              ShortFlightNo: "",
              flightDate: formatDate(new Date()),
              flightNo: ""
            };
    },
    getAirports: function() {
      return fetchAirports();
    },
    querySearch(queryString, cb) {
      var restaurants = this.restaurants;
      var results = queryString
        ? restaurants.filter(this.createFilter(queryString))
        : restaurants;
      // 调用 callback 返回建议列表的数据
      console.log(results);
      cb(results);
    },
    getDiffMinute(deptDate, arrDate) {
      const diff = Date.parse(arrDate) - Date.parse(deptDate);
      const diffMinute = diff / (60 * 1000);
      return diffMinute;
    },
    // createFilter(queryString) {
    //   return restaurant => {
    //     return (
    //       restaurant.chineseName
    //         .toLowerCase()
    //         .indexOf(queryString.toLowerCase()) >= 0 ||
    //       restaurant.airportCode
    //         .toLowerCase()
    //         .indexOf(queryString.toLowerCase()) >= 0
    //     );
    //   };
    // },
    // handleSelectDynamic(item) {
    //   this.dynamicFilter.deptAirport = item.chineseName;
    //   this.dynamicFilter.deptAirportCode = item.airportCode;
    //   console.log(item);
    // },
    // handleSelectDynamic2(item) {
    //   this.dynamicFilter.arrAirport = item.chineseName;
    //   this.dynamicFilter.arrAirportTriCode = item.airportCode;
    //   console.log(item);
    // },
    getAirport(code) {
      return this.restaurants.find(v => v.airportCode == code)
    },
    fetchDynamicDataHandler() {
      if (
        (this.dynamicFilter.deptAirport === "" ||
          this.dynamicFilter.arrAirport === "") &&
        this.loginType === "AIRPORT"
      ) {
        this.$message({
          showClose: true,
          message: "请选择完整的出发到达城市",
          type: "warning"
        });
        return;
      }
      if(this.loginType === "FLIGHTNO" && this.dynamicFilter.ShortFlightNo.trim() === ""){
        this.$message({
          showClose: true,
          message: "请输入航班号",
          type: "warning"
        });
        return;
      }
      this.scheduleLoading = true;
      this.detailVisible = false;
      if (this.dynamicFilter.ShortFlightNo.length > 0) {
        this.dynamicFilter.flightNo =
          "A6" + this.dynamicFilter.ShortFlightNo.replace("A6", "");
      }

      getDynamicData({ t: this.dynamicFilter }).then(res => {
        this.scheduleLoading = false;
        if (res.result) {
          this.tableData = [];
          res.obj.success &&
            (res.obj.values || []).forEach(item => {
              item.flightDate = item.flightDate.split(" ")[0];
              this.tableData.push(item);
            });
          console.log(res);
        }
      });
    },
    handleDetail(row) {
      this.detailVisible = true;
      // 获取详情
      this.dynamicDetail = row;
    },
    handleSearchOnblur(target, pannel, type, event) {
      var that = this;
      var filterResult = [];
      if (type == "dept") {
        if (target.deptAirport) {
          filterResult = this.restaurants.filter(
            this.createFilter(target.deptAirport)
          );
        }
      } else {
        if (target.arrAirport) {
          filterResult = this.restaurants.filter(
            this.createFilter(target.arrAirport)
          );
        }
      }

      if (filterResult && filterResult.length > 0) {
        this.defaultAirport = filterResult[0];
      } else {
        that.defaultAirport = {};
      }
      console.log(that.defaultAirport);
      if (type == "dept") {
        target.deptAirport = that.defaultAirport.cityName
          ? that.defaultAirport.cityName
          : "";
        target.depAirportTriCode = that.defaultAirport.airportCode
          ? that.defaultAirport.airportCode
          : "";
        if (event != null && target.deptAirport) {
          pannel.close();
        }
      } else {
        target.arrAirport = that.defaultAirport.cityName
          ? that.defaultAirport.cityName
          : "";
        target.arrAirportTriCode = that.defaultAirport.airportCode
          ? that.defaultAirport.airportCode
          : "";
        if (event != null && target.arrAirport) {
          //
          //
          pannel.close();
        }
      }
    },
    handleSelectDynamic(type, item) {
      if (type == "dept") {
        this.dynamicFilter.deptAirport = item.cityName;
        this.dynamicFilter.depAirportTriCode = item.airportCode;
      } else {
        this.dynamicFilter.arrAirport = item.cityName;
        this.dynamicFilter.arrAirportTriCode = item.airportCode;
      }

      this.defaultAirport = item;
    },
    querySearchDynamic(queryString, cb) {
      /* 新增选择机场组件 */
      console.log(queryString);
      if (!queryString || queryString.length <= 0) {
        cb([]);
        this.$refs.deptAirportPannelDynamic.show();
        this.$refs.arrAirportPannelDynamic.close();
        this.defaultAirport = {};
        this.handleSearchOnblur(
          this.dynamicFilter,
          this.$refs.deptAirportPannelDynamic,
          "dept",
          null
        );
        return;
      }
      this.$refs.deptAirportPannelDynamic.close();
      var restaurants = this.restaurants;
      var results = queryString
        ? restaurants.filter(this.createFilter(queryString))
        : restaurants;

      if (results != null && results.length > 0) {
        this.defaultAirport = results[0];
      }

      cb(results);
    },
    querySearchDynamic2(queryString, cb) {
      /* 新增选择机场组件 */
      if (!queryString || queryString.length <= 0) {
        cb([]);
        this.$refs.arrAirportPannelDynamic.show();
        this.$refs.deptAirportPannelDynamic.close();
        this.defaultAirport = {};
        this.handleSearchOnblur(
          this.dynamicFilter,
          this.$refs.arrAirportPannelDynamic,
          "arr",
          null
        );
        return;
      }
      this.$refs.arrAirportPannelDynamic.close();
      var restaurants = this.restaurants;
      var results = queryString
        ? restaurants.filter(this.createFilter(queryString))
        : restaurants;

      if (results != null && results.length > 0) {
        this.defaultAirport = results[0];
      }
      cb(results);
    },
    createFilter(queryString) {
      return restaurant => {
        return (
          restaurant.chineseName
            .toLowerCase()
            .indexOf(queryString.toLowerCase()) >= 0 ||
          restaurant.airportCode
            .toLowerCase()
            .indexOf(queryString.toLowerCase()) >= 0
        );
      };
    }
  }
};
</script>
<style scoped lang='less'>
.checkin {
  margin-top: 20px;
  margin-bottom: 20px;
}
.left_pannel {
  height: 500px;
  background-color: #f7f7f7;
  overflow: hidden;
}
.right_pannel {
  min-height: 700px;
  position: relative;
  background-color: #f7f7f7 !important;
  // border: 1px solid #ededed;
}
.panel-tabs .active {
  /* background-color: #d6003a; */
  /* color:#FFFFFF */
}

.panel-tabs .active a {
  /* color:#FFFFFF */
}

.col_menu b {
  font-size: 24px;
  margin: 20px 20px 0;
  color: #333;
  display: block;
  font-weight: normal;
  border-bottom: 1px solid #f1f1f1;
}
.col_menu ul {
  margin-bottom: 20px;
}
ul,
ol,
dl,
dd,
dt {
  margin: 0;
  padding: 0;
  display: block;
}
.col_menu li {
  margin: 10px 0;
  position: relative;
}
.col_menu li.current {
  background: none;
}
.col_menu li.current a:link,
.col_menu li.current a:visited,
.col_menu li.current a:active {
  border-left: 2px #ff6600 solid;
  color: #666;
  font-weight: bold;
}
.col_menu li a:link,
.col_menu li a:visited,
.col_menu li a:active {
  display: block;
  line-height: 30px;
  padding: 0 55px 0 20px;
}
a:link,
a:visited,
a:active {
  color: #666;
  text-decoration: none;
}

a {
  color: #337ab7;
  text-decoration: none;
}
.warm-prompt {
  padding: 0 40px;
}
.warm-prompt p {
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  color: #9d9d9d;
  text-align: left;
}

.service-query {
  position: relative;
  z-index: 10;
  margin: 0 auto;
  padding: 8px 44px 0;
  width: 578px;
  background-color: #fff;
  border: 1px solid #f1f1f1;
  border-radius: 4px;
  /* -webkit-box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.05); */
}
.service-query .service-title {
  font-size: 18px;
  font-weight: 700;
  line-height: 55px;
  color: #4d4e4e;
  text-align: center;
  border-bottom: 1px solid #eae7e7;
}
.service-query .service-prompt {
  padding-top: 8px;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.8;
  color: #9d9d9d;
  text-align: center;
}
.service-query .service-forms > li {
  margin-top: 20px;
}

.form-group .form-label {
  width: 100px;
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  line-height: 35px;
  color: #4d4e4e;
  text-align: right;
}
.checkin-links.query {
  margin: 24px 35px 24px 75px !important;
}

.agree-protocol {
  margin: 24px 0;
  padding-left: 24px;
  font-size: 0;
}
.link.link-sm {
  height: 18px;
  font-size: 12px;
  line-height: 18px;
}

.link {
  height: 20px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #2c6e8b;
  text-align: left;
}

.search-block {
  width: 1200px;
  margin: auto;
  position: relative;
}
.popcontext .tab {
  z-index: 1000;
  width: 240px;
}
.popcontext .tab li {
  height: 60px;
  line-height: 60px;
  /* border-top: 1px solid #4e617f; */
  background-color: #ffffff;
  color: #000000;
  font-size: 14px;
  text-align: center;
  transition: all 0.5s ease 0s;
  -webkit-transition: all 0.5s ease 0s;
  cursor: pointer;
  margin-left: 10px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  filter: alpha(Opacity=60);
  -moz-opacity: 0.6;
  opacity: 0.6;
}

/* border-bottom: 1px solid #d6003a; */
.popcontext .tab li:nth-child(2) {
  border-top: 1px solid #d6003a;
}

.popcontext .tab li:last-child {
  -webkit-border-bottom-left-radius: 5px;
  -moz-border-bottom-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.popcontext .tab li:nth-child(2).active {
  border-top: 1px solid #f9f9f9;
}
.popcontext .tab li.active {
  /* border-top: 1px solid #fff; */
  background-color: #d6003a;
  font-size: 16px;
  color: #ffffff;
  font-weight: bold;
  margin-left: 0;
  padding-left: 10px;
  border-right: 1px solid #fff;
  -webkit-border-radius: 3px 0 0 3px;
  -moz-border-radius: 3px 0 0 3px;
  border-radius: 3px 0 0 3px;
  filter: alpha(Opacity=100);
  -moz-opacity: 1;
  opacity: 1;
}
.popcontext hgroup {
  display: none;
  position: absolute;
  width: 460px;
  top: -410px;
  left: 0px;
  z-index: 1000;
  margin-left: 140px;
  background-color: #fff;
  -webkit-border-radius: 0 5px 5px 0;
  -moz-border-radius: 0 5px 5px 0;
  border-radius: 0 5px 5px 0;
}
.popcontext hgroup article {
  position: relative;
  height: 364px;
  padding-left: 30px;
}

.panel-header {
  /* border-bottom: 2px solid #e9e8e8; */

  width: 400px;
}
.panel-header .panel-tabs {
  display: block;
  width: 100%;
  height: 42px;
  margin: 0;
  padding: 0;
}
.panel-tabs li {
  width: 50%;
  position: relative;
  float: left;
  /* background-color: #EEEEEEFF; */
}
.panel-tabs li:first-child {
  border-radius: 10px 0px 0 0;
}

.panel-tabs li:last-child {
  /* border-radius: 0px 10px 0 0; */
}

.panel-tabs li a {
  position: relative;
  display: block;
  margin: auto;
  width: 100px;
  height: 42px;
  font-size: 14px;
  font-weight: 400;
  line-height: 42px;
  text-align: center;
  cursor: pointer;
}
.panel-tabs .clearfix:after {
  display: block;
  height: 0;
  content: "";
  clear: both;
  overflow: hidden;
}
.panel-tabs .active {
  background-color: #d6003a;
  color: #ffffff;
}

.panel-tabs .active a {
  color: #ffffff;
}

.filter {
  width: 100%;
  float: left;
}
.filter .el-input {
  /* margin-top: 10px; */
}
.el-date-editor .el-input {
  width: 100%;
}
.filter-title {
  margin-top: 15px;
  font-size: 12px;
  color: #7f7e7c;
  font-weight: 700;
}
.filter-input {
  position: relative;
  padding-top: 10px;
}
.filter-input .el-input input {
  height: 45px !important;
  line-height: 45px;
}
.filter-panel {
  background-color: #ffffff;
  position: absolute;
  top: 68px;
  left: 0;
  width: 424px;
  padding: 8px 16px 16px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1000;
}
.filter-panel-title {
  color: #9d9d9d;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.el-select-dropdown__item {
  padding-left: 15px;
}
.separet {
  height: 14px;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  margin: 0 9px;
  margin-top: 50px;
}
.search-btn {
  width: 80px;
  height: 35px;
  margin-top: 20px;
  margin-left: 32px;
  background-color: #ff0505;
  color: #ffffff;
  border: 0;
  font-size: 14px;
  font-weight: 700;
}
.el-tabs__active-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  background-color: #f7214b;
  z-index: 1;
  -webkit-transition: -webkit-transform 0.3s
    cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
    -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
    -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  list-style: none;
}
.panel-tabs li.active:after {
  position: absolute;
  right: 0;
  bottom: -2px;
  left: 0;
  display: block;
  width: 100%;
  height: 2px;
  content: "";
  background-color: #ce212e;
}
.el-input__inner {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 45px;
  line-height: 45px;
  outline: 0;
  padding: 0 15px;
  -webkit-transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
}

.aoctable .el-table__header-wrapper table thead tr {
  background-color: #d70039;
}
.top-pannel {
  width: 940px;
  margin-top: 20px;
  padding-bottom: 10px;
  // -webkit-box-shadow: 0 1px 5px 1px #eaeaea;
  // border: 1px solid #eeeeee;
  // box-shadow: 0 1px 5px 1px #eaeaea;
  border-radius: 10px;
  background: #ffffff;
}
.goback {
  width: 120px;
  height: 20px;
  line-height: 20px;
  padding: 2px 5px;
  background: #d7003a;
  border-radius: 5px;
  color: #fff;
  margin: 5px 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  float: right;
  font-size: 12px;
}
.detail-content {
  height: 400px;
  padding: 0 20px;
}
.detail-content-top {
  border-radius: 10px;
  background: #fff;
  position: relative;
  &-img {
    position: absolute;
    top: 0;
    left: -10px;
    background: url("../../assets/aoc-top.png");
    background-size: cover;
    width: 200px;
    height: 28px;
    color: #ffffff;
    font-size: 12px;
    line-height: 20px;
    padding-left: 10px;
    span {
      margin-right: 2px;
    }
  }
  &-title {
    width: 600px;
    // height: 60px;
    margin: 0 auto;
    font-size: 24px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    span {
      text-align: center;
    }
  }
  &-flight {
    width: 90%;
    height: 60px;
    margin: 0 auto;
    font-size: 20px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #d6003a;
    background: #cccccc3d;
    padding: 0 30px;
    background: url("../../assets/dynamic_flight_bg.png");
    background-position: center right;
    background-size: 100% 100%;
  }
  .detail-content-top-tip {
    text-align: center;
    margin-top: 20px;
    span {
      margin-right: 20px;
    }
    .title {
      color: #9d9d9d;
    }
    .value {
      color: #000000;
    }
  }
}
.detail-content-detail {
  border-radius: 10px;
  padding: 0 20px;
  & > div {
    border-radius: 10px;
    background: #ffffff;
  }
  .pannel {
    padding: 10px 20px;
    width: 400px;

    line-height: 40px;
    border-bottom: 1px solid #f6f6f6;
    span:first-child {
      float: left;
      margin-right: 10px;
    }
    span:nth-child(2) {
      float: left;
      font-size: 20px;
    }
    span:nth-child(3) {
      float: right;
      color: #9d9d9d;
    }
  }
  .date {
    height: 100px;
    width: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
    flex-wrap: wrap;
    &-plan,
    &-true,
    &-order {
      width: 33.3%;
      line-height: 30px;
      height: 50%;
      display: flex;
      flex-wrap: wrap;
      justify-content: left;
      text-align: left;
      span {
        display: block;
      }
      span:first-child {
        color: #9d9d9d;
      }
      span:nth-child(2) {
        //font-size: 20px;
        //font-weight: 400;
      }
      span#detail {
        margin-left: 5px;
        color: #3e3a39;
      }
    }
  }
}
.date-plan span:first-child {
  margin-right: 20px;
}
.fly-status {
  position: absolute;
  top: 0;
  right: 0;
  width: 60px;
  height: 60px;
}
</style>
